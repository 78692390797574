import Illegible from "../../../../posts/two-ways-to-create-blurry-background-images-with-clear-text/components/illegible";
import FailedBlurry from "../../../../posts/two-ways-to-create-blurry-background-images-with-clear-text/components/failed-blurry";
import AbsoluteBlurry from "../../../../posts/two-ways-to-create-blurry-background-images-with-clear-text/components/absolute-blurry";
import GridBlurry from "../../../../posts/two-ways-to-create-blurry-background-images-with-clear-text/components/grid-blurry";
import * as React from 'react';
export default {
  Illegible,
  FailedBlurry,
  AbsoluteBlurry,
  GridBlurry,
  React
};