import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../images/jaromir-kavan-vv-oEGlN-4E-unsplash.jpg';

const S = {};

S.MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow: hidden;
`;

S.BlurryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  max-height: 100%;
  width: 100%;
  flex: 1;

  background: url(${BackgroundImage}) no-repeat center;
  background-size: cover;
`;

S.WannabeClearContent = styled.div`
  font-size: 2rem;
  color: white;
`;

const Illegible = () => {
  return (
    <S.MainContainer>
      <S.BlurryContainer>
        <S.WannabeClearContent>
          <p>Tough to read?</p>
        </S.WannabeClearContent>
      </S.BlurryContainer>
    </S.MainContainer>
  );
};

export default Illegible;