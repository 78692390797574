import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../images/jaromir-kavan-vv-oEGlN-4E-unsplash.jpg';

const S = {};

S.MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

S.BlurryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
  max-height: 100%;
  width: 100%;
  flex: 1;

  background: rgba(0, 0, 0, 0.5) url(${BackgroundImage}) no-repeat center;
  background-size: cover;
  background-blend-mode: darken;
  filter: blur(2px);
`;

S.ClearContent = styled.div`
  position: absolute;
  z-index: 2;
  font-size: 2rem;
  color: white;
  text-align: center;
`;

const AbsoluteBlurry = () => {
  return (
    <S.MainContainer>
      <S.BlurryContainer />
      <S.ClearContent>
        <p>Too Blurry?</p> 
        <p>Nah, this is crystal clear!</p>
      </S.ClearContent>
    </S.MainContainer>
  );
};

export default AbsoluteBlurry;