import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../images/jaromir-kavan-vv-oEGlN-4E-unsplash.jpg';

const S = {};

S.MainContainer = styled.div`
  display: grid;
  grid-template-areas: "Content";
  height: 200px;
  overflow: hidden;
`;

S.BlurryContainer = styled.div`
  grid-area: Content;

  background: rgba(0, 0, 0, 0.5) url(${BackgroundImage}) no-repeat center;
  background-size: cover;
  background-blend-mode: darken;
  filter: blur(2px);
`;

S.ClearContent = styled.div`
  grid-area: Content;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  text-align: center;
`;

const GridBlurry = () => {
  return (
    <S.MainContainer>
      <S.BlurryContainer />
      <S.ClearContent>
        <p>Clear content without absolute positioning!</p>
      </S.ClearContent>
    </S.MainContainer>
  );
};

export default GridBlurry;